'use client';

import { createContext } from 'react';
import { LoginState } from '../../enums/login-state';
import type { AuthenticationData } from '../types';

export const AuthenticationContext = createContext<AuthenticationData>({
    state: LoginState.ANONYMOUS,
    memberId: null,
    shoppingCartId: null,
    isRegistered: false,
    memberships: [],
    isB2B: false,
    clientId: null,
});
