export enum AllerhandeRoute {
    Home = '/allerhande',
    RecipeDetails = '/allerhande/recept',
    VideoDetails = '/allerhande/video',
    RecipeSearch = '/allerhande/recepten-zoeken',
    VideoSearch = '/allerhande/videos-zoeken',
    MyRecipes = '/allerhande/favorieten',
    MyRecipesDetail = '/allerhande/favorieten/categorie',
    MyShoppedRecipes = '/allerhande/favorieten/eerder-gekocht',
    RecipeThemeOverview = '/allerhande/recepten',
    IngredientOverview = '/allerhande/informatie',
    AllerhandeKids = '/allerhande/allerhandekids',
    Kookschrift = '/kookschrift',
    Magazine = '/allerhande/magazine',
    Magazines = '/allerhande/magazines',
    MemberRecipeDetails = '/allerhande/mijn-recept',
    FoodPreferences = '/allerhande/voorkeuren',
}

export enum GlobalRoute {
    Register = '/mijn/inschrijven/invullen?webshop=true',
    Login = '/login',
}
