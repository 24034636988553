import { useContext } from 'react';
import { I18NContext } from '../context/context';
import { translationsCore } from '../translations/translations-core';
export const useTranslations = (fallbackNamespace) => {
    const context = useContext(I18NContext);
    if (context === null) {
        throw new Error('I18NProvider missing...');
    }
    return {
        ...translationsCore(context, fallbackNamespace),
        locale: context.locale,
    };
};
