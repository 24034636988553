import { Locale } from '@royalaholddelhaize/ah-web-core';
export const getCorrectDictionary = (dictionaries, locale) => {
    if (!dictionaries[locale] && dictionaries[Locale.nl_NL]) {
        console.warn(`Dictionary for locale ${locale} not found, falling back to nl_NL`);
        return dictionaries[Locale.nl_NL];
    }
    if (!dictionaries[locale]) {
        throw new Error(`Dictionary for locale ${locale} not found`);
    }
    return dictionaries[locale];
};
