import { AllerhandeRoute } from '@enums/route';

export const LOGGEDON_ROUTES = [AllerhandeRoute.FoodPreferences];

export const AUTHENTICATED_ROUTES = [
    AllerhandeRoute.MyRecipes,
    AllerhandeRoute.MyRecipesDetail,
    AllerhandeRoute.MyShoppedRecipes,
];

export const isAuthenticatedRoute = (path: string): boolean => {
    return AUTHENTICATED_ROUTES.some(route => path.startsWith(route));
};

export const isLoggedOnRoute = (path: string): boolean => {
    return LOGGEDON_ROUTES.some(route => path.startsWith(route));
};
