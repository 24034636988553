'use client';

import { useContext } from 'react';
import type { AuthenticationData } from '../types';
import { AuthenticationContext } from './context';

export const useAuthentication = (): AuthenticationData => {
    const context = useContext(AuthenticationContext);

    if (context === null) {
        throw new Error(
            'useAuthentication must be used within a AuthenticationProvider',
        );
    }

    return context;
};
