'use client';

import ErrorPage from '@components/layouts/error-page/error-page';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { FC } from 'react';

const NotFound: FC = () => {
    const { t } = useTranslations('error');

    return <ErrorPage title={t('not-found')} t={t} />;
};

export default NotFound;
