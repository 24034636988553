'use client';

import {
    isAuthenticatedRoute,
    isLoggedOnRoute,
} from '@constants/navigation/authenticated-routes';
import { AllerhandeRoute } from '@enums/route';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { LoginState } from '@royalaholddelhaize/ah-next-core/src/enums/login-state';
import Link from 'next/link';
import type { AnchorHTMLAttributes, FC } from 'react';

type LinkOrAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * A simple link component switcher, based on the basePath it uses the NextJS link component for prefetching.
 * Else it uses a regular anchor component
 */
export const LinkOrAnchor: FC<LinkOrAnchorProps> = ({ href, ...rest }) => {
    const { state } = useAuthentication();

    if (href && href.startsWith(AllerhandeRoute.Home)) {
        let prefetch = true;

        // Do not prefetch when link requires login
        if (
            isAuthenticatedRoute(href) &&
            ![LoginState.RETURNING, LoginState.LOGGEDON].includes(state)
        ) {
            prefetch = false;
        }

        if (isLoggedOnRoute(href) && state !== LoginState.LOGGEDON) {
            prefetch = false;
        }

        return <Link href={href} prefetch={prefetch} {...rest} />;
    }

    return <a href={href} {...rest} />;
};
