import {
    I18NContext,
    type TranslationsProps,
    useTranslations as useTranslationsMain,
} from '@royalaholddelhaize/ah-i18n';
import { type Locale, setCookie } from '@royalaholddelhaize/ah-web-core';
import { useContext } from 'react';
import { Cookie } from '../../enums/cookie';
import { isBrowser } from '../../utils/is-browser';

type UseTranslationsProps = TranslationsProps & {
    changeLanguage: (locale: Locale) => void;
};

export const useTranslations = (
    fallbackNamespace?: string,
): UseTranslationsProps => {
    const translations = useTranslationsMain(fallbackNamespace);
    const context = useContext(I18NContext);

    if (context === null) {
        throw new Error('useTranslations must be used within a I18NProvider');
    }

    const changeLanguage = (locale: Locale) => {
        if (context.locales.includes(locale) && isBrowser()) {
            const expires = new Date();
            expires.setFullYear(expires.getFullYear() + 10);

            setCookie({
                name: Cookie.LOCALE,
                value: locale,
                expires,
                path: '/',
            });
            location.reload();
        } else {
            console.error(`Locale ${locale} does not exists for this app`);
        }
    };

    return {
        ...translations,
        changeLanguage,
        locale: context.locale,
    };
};
