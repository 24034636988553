'use client';

import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import type { TFunction } from '@royalaholddelhaize/ah-next-core/src/i18n/types';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useMounted } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-mounted/use-mounted';
import type { FC } from 'react';

import css from './error-page.module.scss';

const randomMessage = (t: TFunction) => {
    return t(`messages.${Math.floor(Math.random() * 18)}`);
};

interface ErrorPageProps {
    title?: string;
    t?: TFunction;
}

const ErrorPage: FC<ErrorPageProps> = ({
    title = 'Oops, something went wrong...',
    t,
}) => {
    const { hasMounted } = useMounted();

    const isTranslatable = typeof t === 'function';

    const handleOnBack = () => {
        if (
            window.history.length > 1 &&
            document.referrer.includes(window.location.host)
        ) {
            window.history.back();
        } else {
            location.href = AllerhandeRoute.Home;
        }
    };

    return (
        <Container className={css.container}>
            <Typography variant="display" className={css.title}>
                {title}
            </Typography>

            <Typography className={css.description}>
                {isTranslatable
                    ? t('description.go-back-to')
                    : 'Go back to the'}{' '}
                <button
                    type="button"
                    onClick={handleOnBack}
                    className={css.link}
                >
                    {isTranslatable
                        ? t('description.previous-page')
                        : 'previous page'}
                </button>
                ,{' '}
                {isTranslatable
                    ? t('description.or-back-to')
                    : 'or go back to the'}{' '}
                <LinkOrAnchor href={AllerhandeRoute.Home} className={css.link}>
                    {isTranslatable ? t('description.homepage') : 'homepage'}
                </LinkOrAnchor>
                .
            </Typography>

            {hasMounted && (
                <Typography className={css.quote}>
                    {isTranslatable
                        ? randomMessage(t)
                        : 'Worst kaas scenario...'}
                </Typography>
            )}
        </Container>
    );
};

export default ErrorPage;
