export const setCookie = ({ name, value, expires, path, }) => {
    let cookie = `${name}=${value};`;
    if (expires) {
        cookie += ` expires=${expires.toUTCString()};`;
    }
    if (path) {
        cookie += ` path=${path};`;
    }
    document.cookie = cookie;
};
export const getCookie = (name) => {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`))
        ?.split('=')[1];
};
