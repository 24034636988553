'use client';
import { DEFAULT_LOCALE } from '@royalaholddelhaize/ah-web-core';
import { createContext } from 'react';
export const I18NContext = createContext({
    locales: [DEFAULT_LOCALE],
    locale: DEFAULT_LOCALE,
    namespaces: ['common'],
    defaultNamespace: 'common',
    dictionaries: {},
});
