import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
/**
 * When no namespaces are provided, convert the data to a namespaced structure using the `default` namespace
 */
const convertToNamespaces = (data) => {
    if ('dictionary' in data) {
        const { dictionary, ...rest } = data;
        return {
            ...rest,
            namespaces: ['default'],
            defaultNamespace: 'default',
            dictionaries: {
                default: dictionary,
            },
        };
    }
    return data;
};
/**
 * Split raw input into possible namespace + key
 *
 * @example "store-details:header" => `{ namespace: "store-details", key: "header" }`
 * @example "header" => `{ key: "header" }`
 */
export const splitNsKey = (input) => {
    if (!input.includes(':')) {
        return {
            key: input,
        };
    }
    const split = input.split(':');
    return {
        namespace: split[0],
        key: split[1],
    };
};
/**
 * If options contain the "count" key, prepend this value behind i18n key
 */
export const context = (key, options) => {
    if (!options.context) {
        return key;
    }
    return `${key}_${options.context}`;
};
/**
 * If options contain the "count" key, prepend the plural keyword to i18n key.
 * Using the Intl.PluralRules API specs.
 *
 * @example "person" + { count: 0 } => "person_other"
 * @example "person" + { count: 1 } => "person_one"
 * @example "person" + { count: 2 } => "person_other"
 */
export const plural = (key, options, locale) => {
    if (options.count === undefined || options.count === null) {
        return key;
    }
    // Parse number if value is not a number
    const count = typeof options.count === 'number'
        ? options.count
        : Number.parseInt(options.count.toString(), 10);
    if (Number.isNaN(count)) {
        return key;
    }
    // Make use of the Intl.PluralRules API
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules
    const rules = new Intl.PluralRules(locale, {
        type: options.ordinal === true ? 'ordinal' : 'cardinal',
    });
    return `${key}_${rules.select(count)}`;
};
/**
 * Replace placeholders (`{{...}}`) in text with values from the options
 *
 * @example "Welcome {{name}}!" + { name: "beppie" } -> "Welcome beppie!"
 */
export const interpolate = (text, options) => {
    const interpolatedText = text;
    return interpolatedText.replace(/{{(.+?)}}/gi, (value, placeholder) => {
        const replacement = placeholder
            .trim()
            .split('.')
            .reduce((p, c) => (p && p[c]) ?? null, options);
        if (replacement !== null &&
            typeof replacement !== 'undefined' &&
            typeof replacement !== 'object') {
            return replacement;
        }
        return value;
    });
};
/**
 * Replace nesting (`$t(store-details)`) in text with other translations value
 *
 * @example "Welcome $t(person, { \"count\": 1 })!" -> "Welcome persoon!"
 * @example "Welcome $t(person, { \"count\": 2 })!" -> "Welcome personen!"
 */
export const nesting = (text, options, t) => {
    const nestedText = text;
    return nestedText.replace(/\$t\((.+?)\)/gi, (_value, placeholder) => {
        const [key, ...rest] = placeholder.split(',');
        let nestedOptions = {};
        if (rest.length > 0) {
            nestedOptions = JSON.parse(interpolate(rest.join(','), options));
        }
        return t(key, nestedOptions);
    });
};
export const translationsCore = (props, fallbackNamespace) => {
    const { dictionaries, locale, defaultNamespace = 'common', } = convertToNamespaces(props);
    const t = (rawKey, options = {}) => {
        const { namespace, key } = splitNsKey(rawKey);
        // Append i18n key manipulators
        const keyWithContext = context(key, options);
        const keyWithPlural = plural(keyWithContext, options, locale);
        // Check if there are any namespace dictionaries
        if (Object.keys(dictionaries).length === 0) {
            return key;
        }
        const dictionary = dictionaries[namespace || fallbackNamespace || defaultNamespace];
        // Check if namespace dictionary is empty
        if (!dictionary || Object.keys(dictionary).length === 0) {
            return key;
        }
        // Check if key exists as full root key, else nested split with `.`
        const text = dictionary[keyWithPlural] ||
            keyWithPlural
                .split('.')
                .reduce((acc, k) => {
                if (typeof acc === 'string') {
                    return acc;
                }
                return acc && acc[k];
            }, dictionary);
        // If no translations has been found, return key
        if (!text || typeof text !== 'string') {
            return keyWithPlural;
        }
        // Fill placeholders
        const textInterpolated = interpolate(text, options);
        const textNested = nesting(textInterpolated, options, t);
        return textNested;
    };
    const tRich = (rawKey, options = {}) => {
        const components = [];
        const string = t(rawKey, options);
        const preInterpolatedString = string.replace(/<([^<>]+)>([^<>]+)<\/[^<>]+>/gi, (_value, key, placeholder) => {
            if (key && typeof options[key] === 'function') {
                components[components.length] = options[key](placeholder);
            }
            return '[component]';
        });
        return (_jsx(_Fragment, { children: preInterpolatedString.split('[component]').map((e, idx) => (_jsxs(Fragment, { children: [e, components[idx]] }, `${rawKey}-${idx.toString()}`))) }));
    };
    return {
        t,
        tRich,
    };
};
