'use client';

import type { FC, PropsWithChildren } from 'react';
import type { AuthenticationData } from '../types';
import { AuthenticationContext } from './context';

interface AuthenticationProviderProps extends PropsWithChildren {
    data: AuthenticationData;
}

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
    data,
    children,
}) => {
    return (
        <AuthenticationContext.Provider value={data}>
            {children}
        </AuthenticationContext.Provider>
    );
};
